export const ROOT = {
  ROUTE: '/:language',
  LINK: ({ language }) => `/${language}`
};

export const CHAT_BOT = {
  ROUTE: `/chatbot`,
  ROUTE_RELATIVE: `${ROOT.ROUTE}/chatbot`,
  LINK: ({ language }) => `${ROOT.LINK({ language })}/chatbot`
};

export const CHAT_BOT_FLOW_EDITOR = {
  ROUTE: '/flow-editor',
  ROUTE_RELATIVE: `${CHAT_BOT.ROUTE_RELATIVE}/flow-editor`,
  LINK: ({ language }) => `${CHAT_BOT.LINK({ language })}/flow-editor`
};
