import {
  createBrowserRouter,
  createRoutesFromElements,
  Route
} from 'react-router-dom';

import { CHAT_BOT_FLOW_EDITOR } from './routes';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path={CHAT_BOT_FLOW_EDITOR.ROUTE_RELATIVE}
        lazy={() => import('./pages/FlowEditorPage')}
      />
    </>
  )
);
